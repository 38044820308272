import { createApp, h } from "vue";

import i18nwithInDomMessages from "./i18n.withInDomMessages";
import i18nwithFallbackFiles from "./i18n.withFallbackFiles";
import App from "./App.vue";
import config from "./config";
import Vue3Sanitize from "vue-3-sanitize";
const defaultOptions = {
  allowedTags: ["strong"]
};

const app = createApp({
  render: () => h(App, config)
});
if (window["contentLocale"]) {
  app.use(i18nwithInDomMessages);
} else {
  app.use(i18nwithFallbackFiles);
}
app.use(Vue3Sanitize, defaultOptions);

app.mount("#sm-site-search");
