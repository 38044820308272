
export const showSearchResultsOverlay = () => {
    const searchBoxOverlay = document.querySelector(".searchBoxOverlay");
    const html = document.querySelector("html");


    if (
        searchBoxOverlay.classList.contains("searchResultsActive") ||
        searchBoxOverlay.classList.contains("pc-isActive")
    )
        return;

    searchBoxOverlay.classList.add("searchResultsActive");
    searchBoxOverlay.classList.add("pc-isActive");
    html.classList.add("stopScroll");
};