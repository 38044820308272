import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "searchResultsTotal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createTextVNode(" Showing "),
    _createElementVNode("strong", null, _toDisplayString(_ctx.first), 1),
    _createTextVNode(" - "),
    _createElementVNode("strong", null, _toDisplayString(_ctx.last), 1),
    _createTextVNode(" of "),
    _createElementVNode("strong", null, _toDisplayString(_ctx.total) + " results ", 1)
  ]))
}