
import { computed, ComputedRef, defineComponent } from "vue";
import { totalPages, currentActivePage } from "../common/pagination";
export default defineComponent({
  setup(props, context) {
    const numbersToRender: ComputedRef<number[]> = computed(() => {
      const valsToShow: number[] = [];

      // Most simple case - few enough pages that we can show them all
      if (totalPages.value <= 5) {
        for (let i = 1; i <= totalPages.value; i++) {
          valsToShow.push(i);
        }
        return valsToShow;
      }

      // Other simple cases - within the first or last 4 pages
      if (currentActivePage.value < 4) {
        for (let i = 1; i <= 4; i++) {
          valsToShow.push(i);
        }
        return valsToShow;
      }
      if (currentActivePage.value >= totalPages.value - 2) {
        for (let i = totalPages.value - 3; i <= totalPages.value; i++) {
          valsToShow.push(i);
        }
        return valsToShow;
      }

      // Final case - In the middle somewhere
      if (
        currentActivePage.value > 3 &&
        currentActivePage.value < totalPages.value - 2
      ) {
        for (
          let i = currentActivePage.value - 1;
          i <= currentActivePage.value + 1;
          i++
        ) {
          valsToShow.push(i);
        }
        return valsToShow;
      }
      return valsToShow;
    });

    const goToPage = (newPage) => {
      if (
        newPage > 0 &&
        newPage <= totalPages.value &&
        newPage !== currentActivePage.value
      ) {
        context.emit("pageChanged", newPage);
        document.querySelector(".searchBoxOverlay").scroll({
          top: 0,
          behavior: "smooth"
        });
      }
    };

    return {
      totalPages,
      currentActivePage,
      numbersToRender,
      goToPage
    };
  }
});
