import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  UmbracoAPIsOptionalParams,
  DictionarySurfaceOptionalParams,
  SearchOptionalParams,
  SearchResponse,
  SettingsOptionalParams,
  SettingsResponse
} from "./models";

export class UmbracoAPIs extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the BritvicUmbracoAPIs class.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor($host: string, options?: UmbracoAPIsOptionalParams) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: UmbracoAPIsOptionalParams = {
      requestContentType: "application/json; charset=utf-8"
    };

    const packageDetails = `azsdk-js-britvicUmbracoAPIs/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix
      },
      baseUri: options.endpoint ?? options.baseUri ?? "{$host}"
    };
    super(optionsWithDefaults);

    if (options?.pipeline && options.pipeline.getOrderedPolicies().length > 0) {
      const pipelinePolicies: coreRestPipeline.PipelinePolicy[] = options.pipeline.getOrderedPolicies();
      const bearerTokenAuthenticationPolicyFound = pipelinePolicies.some(
        (pipelinePolicy) =>
          pipelinePolicy.name ===
          coreRestPipeline.bearerTokenAuthenticationPolicyName
      );
      if (!bearerTokenAuthenticationPolicyFound) {
        this.pipeline.removePolicy({
          name: coreRestPipeline.bearerTokenAuthenticationPolicyName
        });
        this.pipeline.addPolicy(
          coreRestPipeline.bearerTokenAuthenticationPolicy({
            scopes: `${optionsWithDefaults.baseUri}/.default`,
            challengeCallbacks: {
              authorizeRequestOnChallenge:
                coreClient.authorizeRequestOnClaimChallenge
            }
          })
        );
      }
    }
    // Parameter assignments
    this.$host = $host;
  }

  /** @param options The options parameters. */
  dictionarySurface(options?: DictionarySurfaceOptionalParams): Promise<void> {
    return this.sendOperationRequest(
      { options },
      dictionarySurfaceOperationSpec
    );
  }

  /** @param options The options parameters. */
  search(options?: SearchOptionalParams): Promise<SearchResponse> {
    return this.sendOperationRequest({ options }, searchOperationSpec);
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  settings(
    id: number,
    options?: SettingsOptionalParams
  ): Promise<SettingsResponse> {
    return this.sendOperationRequest({ id, options }, settingsOperationSpec);
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const dictionarySurfaceOperationSpec: coreClient.OperationSpec = {
  path: "/site-settings/dictionary",
  httpMethod: "GET",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const searchOperationSpec: coreClient.OperationSpec = {
  path: "/api/search/query",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ISiteSearchResult
    }
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const settingsOperationSpec: coreClient.OperationSpec = {
  path: "/api/settings/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.IndexSettingsViewModel
    }
  },
  queryParameters: [Parameters.culture],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
