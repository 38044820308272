import { QueryString } from "../models/queryString";

export const parseQueryString = (): QueryString => {
  const uri: string[] = window.location.href.split("?");
  if (uri.length === 2) {
    const qsOptions: string[] = uri[1].split("&");
    const queryStringValues: QueryString = {};

    qsOptions.forEach(qsOpt => {
      const qsKVP = qsOpt.split("=");
      if (qsKVP.length == 2) {
        queryStringValues[qsKVP[0]] = qsKVP[1];
      }
    });
    return queryStringValues;
  }
  return {};
};

export const setQueryString = (
  newQSValues: QueryString,
  keepQSValues = true
): string => {
  const uri: string = window.location.href.split("?")[0];

  const qsToUse: QueryString = {
    ...(keepQSValues ? parseQueryString() : {}),
    ...newQSValues
  };
  const qsValues: string[] = [];
  for (const key in qsToUse) {
    qsValues.push(`${key}=${qsToUse[key]}`);
  }

  if (qsValues.length === 0) {
    return uri;
  }

  return `${uri}?${qsValues.join("&")}`;
};

export const removeQueryStringValues = (qSValuesToRemove: string[]): string => {
  const newQS: QueryString = parseQueryString();

  qSValuesToRemove.forEach((key: string) => {
    if (newQS[key]) {
      delete newQS[key];
    }
  });

  return setQueryString(newQS, false);
};
