const defaultSearchRoot = -1;
const defaultPageSize = 20;
const defaultPersonalisationGroupIds = "";
const defaultBaseUri = "";
const defaultLanguage = "";
const defaultSiteLogo = "";

const indexElement = document.getElementById("sm-site-search");

let searchRoot = defaultSearchRoot;
const castSearchRoot = Number(indexElement?.dataset?.searchRoot);
if (!isNaN(castSearchRoot) && castSearchRoot !== 0) searchRoot = castSearchRoot;

let pageSize = defaultPageSize;
const castPageSize = Number(indexElement?.dataset?.pageSize);
if (!isNaN(castPageSize) && castPageSize !== 0) pageSize = castPageSize;

const personalisationGroupIds =
  indexElement?.dataset?.personalisationGroupIds ??
  defaultPersonalisationGroupIds;

const baseUri = indexElement?.dataset?.baseUri ?? defaultBaseUri;

const language = indexElement?.dataset?.language ?? defaultLanguage;

const siteLogo = indexElement?.dataset?.siteLogo ?? defaultSiteLogo;

const config = {
  searchRoot,
  pageSize,
  personalisationGroupIds,
  baseUri,
  language,
  siteLogo
};

export default config;
