
import SearchBoxOverlay from "../components/SearchBoxOverlay.vue";
import SearchResults from "../components/SearchResults.vue";

import {
  keyword,
  keywordInput,
  searchRoot,
  updateKeyword,
  clearKeyword,
  clearSearchRoot
} from "../common/searching";

import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import { QueryString } from "../models/queryString";
import { parseQueryString } from "../common/urlHelpers";
import { showSearchResultsOverlay } from "../common/uiHelpers";
export default defineComponent({
  components: {
    SearchBoxOverlay,
    SearchResults
  },
  props: {
    pageSize: {
      type: Number,
      required: true,
      default: 20
    },
    personalisationGroupIds: {
      type: String,
      required: false,
      default: ""
    },
    baseUri: {
      type: String,
      required: false,
      default: ""
    },
    language: {
      type: String,
      required: false,
      default: ""
    },
    siteLogo: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup(props, context) {
    const showSearchBoxOverlay = ref(false);
    const showSearchResults = ref(false);

    const handleBackButton = event => {
      applyQueryStringSearch();
    }

    onMounted(() => {
      window.addEventListener('popstate', handleBackButton)
      applyQueryStringSearch();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', handleBackButton)
    });

    const openSearchOverlay = () => {
      showSearchBoxOverlay.value = true;
    };

    const closeSearchOverlay = () => {
      clearKeyword();
      clearSearchRoot();
      showSearchBoxOverlay.value = false;
    };

    const openSearchResults = () => {
      showSearchResults.value = true;
      showSearchBoxOverlay.value = false;
    };

    const closeSearchResults = () => {
      clearKeyword();
      clearSearchRoot();
      showSearchResults.value = false;
    };

    const applyQueryStringSearch = () => {
      const qsParams: QueryString = parseQueryString();
      const { query, searchRootId } = qsParams;

      if (searchRootId) {
        searchRoot.value = parseInt(searchRootId);
      }

      if (query) {
        keyword.value = decodeURIComponent(query);
        updateKeyword();
        showSearchResults.value = true;

        showSearchResultsOverlay();
      }
    }

    return {
      openSearchOverlay,
      closeSearchOverlay,
      openSearchResults,
      closeSearchResults,
      updateKeyword,
      clearKeyword,
      showSearchBoxOverlay,
      showSearchResults,
      keyword,
      keywordInput
    };
  }
});
