import { createI18n } from "vue-i18n";
const myLocale = window["contentLocale"];
const myLocaleTranslations = window["contentDictionary"];

const loadLocaleMessages = () => {
  const languages = {};
  const root = {};
  languages[myLocale] = root;

  for (const key in myLocaleTranslations) {
    const segments = key.split(".");
    let currentObj = root;
    let i = 0;
    const last = segments.length;
    segments.forEach(segment => {
      i++;
      if (i != last) {
        if (!currentObj[segment]) {
          currentObj[segment] = {};
        }
        currentObj = currentObj[segment];
      } else {
        currentObj[segment] = myLocaleTranslations[key];
      }
    });
  }
  return languages;
};

const i18n = createI18n({
  locale: myLocale,
  fallbackLocale: myLocale,
  messages: loadLocaleMessages()
});

export default i18n;
