import {
  totalPages,
  calculateTotalPages,
  currentActivePage
} from "./pagination";
import { ref } from "vue";
import config from "@/config";

const keyword = ref("" as string);
const keywordInput = ref("" as string);

const searchRoot = ref(config.searchRoot);

const updateKeyword = (): void => {
  keywordInput.value = keyword.value;
};

const clearKeyword = (): void => {
  keywordInput.value = "";
  keyword.value = "";
};

const clearSearchRoot = (): void => {
  searchRoot.value = config.searchRoot;
}

const resourcesChanged = (update, pageSize: number): void => {
  currentActivePage.value = update.currentPage;
  const total = update.totalResults !== undefined ? update.totalResults : 0;
  totalPages.value = calculateTotalPages(total, pageSize);
};

export { resourcesChanged, clearKeyword, updateKeyword, clearSearchRoot, keywordInput, keyword, searchRoot };
