
import { defineComponent } from "vue";
export default defineComponent({
  name: "ResultsIndicator",
  props: {
    first: {
      type: Number,
      required: true,
      default: 0,
    },
    last: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    term: {
      type: String,
      required: true,
      default: "",
    }
  },
});
