import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "searchResultDescription"
}
const _hoisted_5 = {
  key: 2,
  class: "searchResultLink"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.results, (resultItem) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "siteSearchResultItem",
        key: resultItem.id
      }, [
        _createElementVNode("a", {
          href: resultItem.url
        }, [
          (resultItem.pageTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(resultItem.pageTitle), 1))
            : _createCommentVNode("", true),
          (resultItem.pageDescription)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(resultItem.pageDescription), 1))
            : _createCommentVNode("", true),
          (resultItem.url)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(resultItem.url), 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}