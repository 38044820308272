import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "searchContentWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBoxOverlay = _resolveComponent("SearchBoxOverlay")!
  const _component_SearchResults = _resolveComponent("SearchResults")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchBoxOverlay, { onSearchPerformed: _ctx.openSearchResults }, null, 8, ["onSearchPerformed"]),
    (_ctx.showSearchResults)
      ? (_openBlock(), _createBlock(_component_SearchResults, {
          key: 0,
          pageSize: _ctx.pageSize,
          personalisationGroupIds: _ctx.personalisationGroupIds,
          apiendpoint: _ctx.baseUri,
          language: _ctx.language,
          siteLogo: _ctx.siteLogo,
          onCloseResultsClick: _ctx.closeSearchResults
        }, null, 8, ["pageSize", "personalisationGroupIds", "apiendpoint", "language", "siteLogo", "onCloseResultsClick"]))
      : _createCommentVNode("", true)
  ]))
}