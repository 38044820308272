export default {
  "Web.Containers.LoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There has been an error loading filters"])},
  "Web.Containers.LoadingFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading filters..."])},
  "Web.Containers.SearchLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by keyword"])},
  "Web.Containers.LoadingResourceError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There has been an error loading resources!"])},
  "Web.Containers.LoadingResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading results..."])},
  "Web.Containers.ClearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "Web.Containers.ClearKeyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear keyword"])},
  "Web.Containers.SubmitSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit search"])},
  "Web.Containers.NoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no results to show"])},
  "Web.Containers.ResultsIndicatorShowing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "Web.Containers.ResultsIndicatorOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "Web.Containers.ResultsIndicatorFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "Web.Containers.ResultsIndicatorResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "Web.Containers.OpenFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open more filters"])},
  "Web.Containers.More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "Web.Containers.PublishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "Web.Containers.Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Web.Containers.Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Web.Components.SearchBoxCTA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Web.Containers.Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Web.Containers.CloseSearchOverlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Web.Components.SearchResultsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Web.Components.SearchResultsNoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
  "Web.Components.SearchResultsTryAnotherSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try another search"])}
}