import { ref } from "vue";

const currentActivePage = ref(1 as number);

const totalPages = ref(0);

const firstPage = (currentPage: number, pageSize: number): number => {
  return (currentPage - 1) * pageSize + 1;
};

const lastPage = (total: number, pageSize: number): number => {
  return Number(currentActivePage.value) * pageSize > total
    ? total
    : Number(currentActivePage.value) * pageSize;
};

const calculateTotalPages = (total: number, pageSize: number): number => {
  return Math.ceil(total / pageSize);
};

const paginationClick = (page: number): void => {
  const elmnt = document.getElementById("resources");
  if (elmnt) {
    elmnt.scrollIntoView();
  }
  currentActivePage.value = page;
};

export {
  paginationClick,
  lastPage,
  firstPage,
  calculateTotalPages,
  totalPages,
  currentActivePage
};
