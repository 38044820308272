
import { defineComponent } from "vue";
import config from "./config";
import GlobalSearchContainer from "./containers/GlobalSearchContainer.vue";
export default defineComponent({
  components: {
    GlobalSearchContainer
  },
  props: {
    pageSize: {
      type: Number,
      required: true,
      default: config.pageSize
    },
    personalisationGroupIds: {
      type: String,
      required: false,
      default: config.personalisationGroupIds
    },
    baseUri: {
      type: String,
      required: false,
      default: ""
    },
    language: {
      type: String,
      required: false,
      default: ""
    },
    siteLogo: {
      type: String,
      required: false,
      default: config.siteLogo
    }
  }
});
