
import RenderSearchResults from "./renderless/RenderSearchResults.vue";
import Pagination from "../components/Pagination.vue";

import {
  paginationClick,
  lastPage,
  firstPage,
  currentActivePage,
} from "../common/pagination";
import {
  resourcesChanged,
  updateKeyword,
  keywordInput,
  keyword,
  clearKeyword,
} from "../common/searching";

import ResultsIndicator from "../components/ResultsIndicator.vue";
import ResultItemsContainer from "../components/ResultItemsContainer.vue";

import { defineComponent, ref } from "vue";
import { removeQueryStringValues, setQueryString } from "../common/urlHelpers";

export default defineComponent({
  components: {
    RenderSearchResults,
    Pagination,
    ResultsIndicator,
    ResultItemsContainer,
  },
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: "",
    },
    pageSize: {
      type: Number,
      required: true,
      default: 20,
    },
    personalisationGroupIds: {
      type: String,
      required: false,
      default: "",
    },
    apiendpoint: {
      type: String,
      required: false,
      default: "",
    },
    language: {
      type: String,
      required: false,
      default: "",
    },
    siteLogo: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, context) {
    const keywordError = ref(false);

    const keywordLengthOK = () => keyword && keyword.value.length > 2;

    const trySearch = () => {
      const keywordOK = keywordLengthOK();
      keywordError.value = !keywordOK;
      if (keywordOK) {
        const url: string = setQueryString({ query: keyword.value });
        window.history.pushState({}, "", url);
        updateKeyword();
      }
    };

    const closeResults = () => {
      const url: string = removeQueryStringValues(["query", "searchRootId"]);
      window.history.pushState({}, "", url);
      context.emit("closeResultsClick");

      // remove class from overlay
      const searchBoxOverlay = document.querySelector(".searchBoxOverlay");
      const html = document.querySelector("html");
      searchBoxOverlay.classList.remove("searchResultsActive");
      html.classList.remove("stopScroll");

      // remove open search bar from main navigation
      const searchButton = document.querySelector(".globalSearchButton");
      searchButton.classList.remove("pc-isActive");
      searchButton.setAttribute("aria-expanded", "false");

      searchBoxOverlay.classList.remove("pc-isActive");
    };

    return {
      trySearch,
      keywordLengthOK,
      closeResults,
      resourcesChanged,
      paginationClick,
      lastPage,
      firstPage,
      keyword,
      clearKeyword,
      keywordInput,
      keywordError,
      currentActivePage,
    };
  },
});
